import moment from "moment"

const agendaDataParser = agendaObject => {
	let incrementalTimeRangeID = 0 // every time range has a unique ID !!!within the cleaner line!!!
	let timeRanges = agendaObject.timeslots.map(slot => {
		let slotStart = moment(slot.start_time)
		let slotEnd = moment(slot.end_time)
		let timeRangeDuration = slotEnd.diff(slotStart, "minutes")
		let extraInfo = {}
		if (slot.type === "booking") {
			extraInfo = {
				bookingId: slot.id,
				parentBookingId: slot.parent_booking_id,
				recurrent: slot.recurrence,
				recurrenceDelay: slot.recurrence_delay,
				customerName: slot.customer_display_name,
				city: slot.town,
				zip: slot.zip_code,
				voucherType: slot.voucher_type,
				note: slot.note,
				customerSodexoReference: slot.customer_sodexo_reference,
				customerTelephone: slot.customer_telephone,
				address: slot.address,
				checkedIn: slot.checked_in,
				checkedOut: slot.checked_out,
				paperVouchersCollected: slot.paper_vouchers_collected,
				billableHours: slot.billable_hours,
				sentToSodexo: slot.sent_to_sodexo,
				dayPeriod: slot.day_period,
				forcedSSCode: slot.forced_social_secretary_code,
			}
		} else if (slot.type === "absence") {
			extraInfo = {
				absenceId: slot.id,
				activityCode: slot.activity_code,
				activityCategory: slot.activity_category,
				affiliateNote: slot.affiliate_note,
				absenceType: slot.motif_type,
				workerNote: slot.worker_note,
			}
		} else if (slot.type === "extra_availability") {
			extraInfo = { extraAvailId: slot.id }
		} else if (slot.type === "other_activity") {
			extraInfo = {
				otherActivityID: slot.id,
				category: slot.category,
				code: slot.code,
				recurrent: slot.recurrence,
				dayPeriod: slot.day_period,
				affiliateNote: slot.affiliate_note,
				isWorkshopHour: slot.is_workshop_hour,
				workshopId: slot.workshop_id,
				type: "other_activity",
				customClass: slot.is_workshop_hour ? "workshop_hour" : "",
			}
		}
		if (slot.extra_hours) {
			extraInfo.subTimeranges = slot.extra_hours.map(eh => ({
				start: moment(eh.start_time),
				end: moment(eh.end_time),
				type: "extra_hour",
				duration: moment(eh.end_time).diff(moment(eh.start_time), "minutes"),
				extra: eh.above38,
			}))
		}
		return {
			id: incrementalTimeRangeID++,
			start: slotStart,
			end: slotEnd,
			type: slot.type,
			duration: timeRangeDuration,
			overlapCount: -1,
			overlapIndex: 0,
			...extraInfo,
		}
	})

	// mark (count & index) overlapping timeranges
	timeRanges.forEach(tr => {
		timeRanges.forEach(subTr => {
			if (tr.id !== subTr.id && tr.start.isBefore(subTr.end) && tr.end.isAfter(subTr.start)) {
				tr.overlapCount = tr.overlapCount > -1 ? tr.overlapCount + 1 : 1
				if (subTr.overlapIndex > tr.overlapIndex) {
					subTr.overlapIndex += 1
				}
			}
		})
		if (tr.overlapIndex === 0) {
			tr.overlapIndex = 1
		}
	})
	let cleaner = {
		name: `${agendaObject.worker.first_name} ${agendaObject.worker.last_name}`,
		first_name: agendaObject.worker.first_name,
		last_name: agendaObject.worker.last_name,
		initials:
			agendaObject.worker.first_name.substring(0, 1) +
			agendaObject.worker.last_name.substring(0, 1),
		id: agendaObject.worker.contract_id,
		sodexoNumber: agendaObject.worker.sodexo_number,
		affiliateWorkerId: agendaObject.worker.id,
		lastEuAbsenceDate: agendaObject.worker.last_eu_absence_date
			? moment(agendaObject.worker.last_eu_absence_date)
			: moment.invalid(),
		customers: timeRanges
			.filter(tr => tr.type === "booking")
			.reduce((acc, tr) => {
				let lowerName = tr.customerName.toLowerCase()
				if (acc.indexOf(lowerName) === -1) {
					acc.push(lowerName)
				}
				return acc
			}, []),
		contractStart: moment(agendaObject.worker.contract_start),
		contractEnd: agendaObject.worker.contract_end
			? moment(agendaObject.worker.contract_end)
			: false,
		areas: agendaObject.worker.areas,
		vehicleType: agendaObject.worker.vehicle_type,
	}
	let weekData = {
		contractHours: agendaObject.week_data.contract_hours,
		workedHours: agendaObject.week_data.worked_hours,
		vacantHours: agendaObject.week_data.vacant_hours,
		badge: agendaObject.week_data.badge,
	}
	return { timeRanges, cleaner, weekData }
}

const typeMap = {
	BOOKING: "booking",
	ABSENCE: "absence",
	AVAILABILITY: "daily_schedule",
	THEORETICAL_HOUR: "theoretical_hour",
	// "AVAILABILITY": "extra_availability",
	UNPLANNED_WORK: "unplanned_work",
	OTHER: "other_activity",
	HOLIDAY: "holiday",
	WORKSHOP_SHIFT: "workshop_shift",
}

const timeRangeObject = (event, trStart, trEnd) => {
	return {
		id: event.occurrence_id,
		realEventId: event.real_event_id,
		bookingId: `${event.event.old_equivalent_id}_${event.event.exception_for_scheduled_date ||
			trStart.format("YYYY-MM-DD")}`,
		otherActivityID: event.event.old_equivalent_id,
		absenceId: event.event.old_equivalent_id,
		start: trStart,
		end: trEnd,
		eventStartDate: moment(event.event.start_date),
		unavStartDate: event.meta.unavailability_start_date
			? moment(event.meta.unavailability_start_date).set({
					hour: event.event.start_time.hour,
					minute: event.event.start_time.minute,
			  })
			: moment(event.event.start_date),
		motifType: event.meta.motif_type,
		unavEndDate: event.meta.unavailability_end_date
			? moment(event.meta.unavailability_end_date).set({
					hour: event.event.end_time.hour,
					minute: event.event.end_time.minute,
			  })
			: moment(event.event.end_date),
		eventEndDate: event.event.end_date ? moment(event.event.end_date) : null,
		dayPeriod: event.event.day_period,
		type: typeMap[event.event.event_type],
		duration: event.duration / 60, // converts the duration to minutes
		overlapCount: -1,
		overlapIndex: 0,
		customerName: event.customer_display_name,
		customerLastName: event.customer_last_name,
		voucherType: event.event.voucher_type,
		affiliateNote: event.event.note,
		sentToSodexo: event.sent_to_sodexo,
		checkedOut: event.checked_out,
		billableHours: event.billable_hours,
		paperVouchersCollected: event.paper_vouchers_collected,
		recurrent: event.event.day_period !== 0,
		code: event.event.ss_code,
		customerSodexoReference: event.customer_sodexo_reference,
		workerSodexoReference: event.worker_sodexo_reference,
		workerSocialSecretary: event.worker_social_secretary_identifier,
		laundryOrders: event.laundry_orders,
		customerTelephone: event.customer_telephone,
		customerContractId: event.event.customer_contract_id,
		address: event.address,
		addressId: event.address_id,
		computed: event.meta.computed,
		workshopId: event.event.workshop_id,
		isWorkshopHour: !!event.event.workshop_id,
		isReplacement: event.is_replacement,
		exceptionType: event.meta.cancel_reason,
		replacementInfos: event.replacement_infos,
		paperVouchersOrigin: event.paper_vouchers_origin,
		showCheckmark: event.show_checkmark,
		blueFill: event.blue_fill,
		doubleMark: event.double_mark,
	}
}

export const agendaEventDataParser = (agendaObject, weekStart) => {
	let weekEnd = moment(weekStart).add(6, "days")
	let timeRanges = []
	let absenceEvents = []
	Object.entries(agendaObject.events_by_date).forEach(([date, events_with_absences]) => {
		let events = events_with_absences.filter(e => {
			if (e.event.event_type === "ABSENCE") {
				let trStart = moment(date).set({
					hour: e.start_time.hour,
					minute: e.start_time.minute,
				})
				let trEnd = moment(date).set({
					hour: e.end_time.hour,
					minute: e.end_time.minute,
				})
				if (trStart.isBefore(weekEnd) && trEnd.isAfter(weekStart)) {
					absenceEvents.push(e)
				}
				return false
			}
			return true
		})
		events.forEach(event => {
			let trStart = moment(date).set({
				hour: event.start_time.hour,
				minute: event.start_time.minute,
			})
			let trEnd = moment(date).set({
				hour: event.end_time.hour,
				minute: event.end_time.minute,
			})
			if (trStart.isBefore(weekStart) || trEnd.isAfter(weekEnd)) return
			if (
				event.comp_seconds_included !== undefined &&
				event.extra_seconds_included !== undefined
			) {
				let compSeconds = event.comp_seconds_included
				let extraSeconds = event.extra_seconds_included
				let totalUnplanned = compSeconds + extraSeconds
				if (compSeconds > 0) {
					let compWorkEvent = {
						id: event.trackable_id,
						start: moment(trEnd).subtract(totalUnplanned, "seconds"),
						end: moment(trEnd).subtract(extraSeconds, "seconds"),
						dayPeriod: event.event.day_period,
						type: "unplanned_work",
						customClass: "comp_work",
						duration: event.duration / 60, // converts the duration to minutes
						overlapCount: -1,
						overlapIndex: 0,
					}
					timeRanges.push(compWorkEvent)
				}
				if (extraSeconds > 0) {
					let extraWorkEvent = {
						id: event.trackable_id,
						start: moment(trEnd).subtract(extraSeconds, "seconds"),
						end: moment(trEnd),
						dayPeriod: event.event.day_period,
						type: "unplanned_work",
						customClass: "extra_work",
						duration: event.duration / 60, // converts the duration to minutes
						overlapCount: -1,
						overlapIndex: 0,
					}
					timeRanges.push(extraWorkEvent)
				}
			}
			if (event.event.event_type === "UNPLANNED_WORK") {
				return
			}
			timeRanges.push(timeRangeObject(event, trStart, trEnd))
		})
	})

	let reassembledAbsences = {}
	absenceEvents.forEach(absenceEvent => {
		let trStart = moment(absenceEvent.event.start_date).set({
			hour: absenceEvent.start_time.hour,
			minute: absenceEvent.start_time.minute,
		})
		let trEnd = moment(absenceEvent.event.start_date).set({
			hour: absenceEvent.end_time.hour,
			minute: absenceEvent.end_time.minute,
		})
		if (reassembledAbsences[absenceEvent.id] === undefined) {
			reassembledAbsences[absenceEvent.id] = timeRangeObject(absenceEvent, trStart, trEnd)
		} else {
			let currAbsence = reassembledAbsences[absenceEvent.id]
			if (trStart.isBefore(currAbsence.start)) {
				currAbsence.start = moment(absenceEvent.event.start_date)
				currAbsence.eventStartDate = moment(absenceEvent.event.start_date)
			}
			if (trEnd.isAfter(currAbsence.end)) {
				currAbsence.end = moment(absenceEvent.event.end_date).set({
					hour: absenceEvent.end_time.hour,
					minute: absenceEvent.end_time.minute,
				})
				currAbsence.eventEndDate = moment(absenceEvent.event.end_date)
			}
		}
	})
	Object.entries(reassembledAbsences).forEach(([key, value]) => timeRanges.push(value))

	timeRanges.forEach(tr => {
		if (tr.type === "unplanned_work" || tr.type === "absence") {
			return
		}
		timeRanges.forEach(subTr => {
			if (subTr.type === "unplanned_work") {
				return
			}
			if (tr.id !== subTr.id && tr.start.isBefore(subTr.end) && tr.end.isAfter(subTr.start)) {
				tr.overlapCount = tr.overlapCount > -1 ? tr.overlapCount + 1 : 1
				if (subTr.overlapIndex > tr.overlapIndex) {
					subTr.overlapIndex += 1
				}
			}
		})
		if (tr.overlapIndex === 0) {
			tr.overlapIndex = 1
		}
	})
	let cleaner = {
		id: agendaObject.worker.id,
		first_name: agendaObject.worker.first_name,
		last_name: agendaObject.worker.last_name,
		name: agendaObject.worker.display_name,
		initials: agendaObject.worker.initials,
		workInterruption: agendaObject.worker.work_interruption,
		excludedHolidays: agendaObject.worker.excluded_holidays_by_date || {},
		sodexoNumber: agendaObject.worker.sodexo_reference,
		affiliateWorkerId: agendaObject.worker.affiliate_worker_id,
		lastEuAbsenceDate: moment.invalid(),
		contractStart: moment(agendaObject.worker.start_date),
		contractEnd: agendaObject.worker.end_date ? moment(agendaObject.worker.end_date) : false,
		vehicleType: agendaObject.worker.vehicle_type,
	}
	return {
		timeRanges,
		cleaner,
		weekData: agendaObject.weeks_metadata[weekStart.format("YYYY-MM-DD")] || {
			vacant_seconds_for_week: 0,
			display_data: [],
		},
	}
}

export const normalizeWorkerAgendaLine = (agendaObj, weekStart, mutableObject) => {
	let days = [],
		weekEnd = moment(weekStart).add(7, "days")
	for (let m = moment(weekStart); m.isSame(weekStart, "isoweek"); m.add(1, "day")) {
		days.push(moment(m))
	}
	let emptyRanges = days.reduce((acc, day, index) => {
		let daySlots = agendaObj.timeRanges.filter(
			slot =>
				slot.realEventId > 0 &&
				(day.isSame(slot.start, "day") || day.isSame(slot.end, "day"))
		)
		let startHour = moment(day).hour(7)
		let endHour = moment(day).hour(19)
		let sortedTimes = daySlots
			.reduce((acc, slot) => {
				if (slot.start.isAfter(startHour) || slot.end.isBefore(endHour)) {
					acc.push({ time: slot.start, type: "start" }, { time: slot.end, type: "end" })
				}
				return acc
			}, [])
			.sort((a, b) => a.time - b.time)
		let emptyRanges = []
		let currEmptyRange = {
			id: (mutableObject.emptyRangeSequentialId++).toString(),
			type: "emptyRange",
		}
		if (sortedTimes.length === 0) {
			emptyRanges.push({
				id: (mutableObject.emptyRangeSequentialId++).toString(),
				start: moment(startHour),
				end: moment(endHour),
				duration: 720,
				type: "emptyRange",
			})
		}
		for (let i = 0; i < sortedTimes.length; i++) {
			let currTime = sortedTimes[i]
			if (i === 0 && currTime.type === "start") {
				// an emptyRange starting at 7 must be added
				let er = {
					id: (mutableObject.emptyRangeSequentialId++).toString(),
					type: "emptyRange",
					start: moment(startHour),
					end: moment(currTime.time),
				}
				let duration = er.end.diff(er.start, "minutes")
				if (duration <= 0) {
					continue
				}
				emptyRanges.push({ ...er, duration })
			} else if (i === sortedTimes.length - 1 && currTime.type === "end") {
				// an emptyRange ending at 20 must be added
				let er = {
					id: (mutableObject.emptyRangeSequentialId++).toString(),
					type: "emptyRange",
					start: moment(currTime.time),
					end: moment(endHour),
				}
				let duration = er.end.diff(er.start, "minutes")
				if (duration <= 0) {
					continue
				}
				emptyRanges.push({ ...er, duration })
			} else if (currTime.type === "start") {
				currEmptyRange.end = moment(currTime.time)
			} else if (currTime.type === "end") {
				currEmptyRange.start = moment(currTime.time)
			}
			if (currEmptyRange.start && currEmptyRange.end) {
				currEmptyRange.duration = currEmptyRange.end.diff(currEmptyRange.start, "minutes")
				if (currEmptyRange.duration <= 0) {
					currEmptyRange = {
						id: (mutableObject.emptyRangeSequentialId++).toString(),
						type: "emptyRange",
					}
					continue
				}
				emptyRanges.push(currEmptyRange)
				currEmptyRange = {
					id: (mutableObject.emptyRangeSequentialId++).toString(),
					type: "emptyRange",
				}
			}
		}
		return acc.concat(emptyRanges)
	}, [])
	let timeRanges = emptyRanges.concat(agendaObj.timeRanges)
	// Adding blocking time ranges for days before & after the contract end
	let contractStartWithinCurrentWeek = agendaObj.cleaner.contractStart.isAfter(weekStart)
	let contractEndWithinCurrentWeek =
		agendaObj.cleaner.contractEnd && agendaObj.cleaner.contractEnd.isBefore(weekEnd)
	let contractEnded = agendaObj.cleaner.contractEnd
		? moment(agendaObj.cleaner.contractEnd).add(1, "day")
		: moment.invalid() // the contract is finished the next day
	if (contractStartWithinCurrentWeek || contractEndWithinCurrentWeek) {
		days.forEach(m => {
			if (
				m.isSameOrAfter(contractEnded, "day") ||
				m.isBefore(agendaObj.cleaner.contractStart, "day")
			) {
				timeRanges.push({
					start: moment(m).hour(0),
					end: moment(m).hour(23),
					duration: 720,
					type: "outsideContract",
				})
			}
		})
	}

	return {
		timeRanges,
		weekStart: moment(weekStart),
		cleaner: agendaObj.cleaner,
		weekData: agendaObj.weekData,
	}
}

export const normalizeEventAgendaData = data => {
	let weekStart = moment(data.start_time)
	let parsedAgendaData = data.events.map(entry => agendaEventDataParser(entry, weekStart))
	let mutableObject = { emptyRangeSequentialId: 0 }
	let fmtData = parsedAgendaData.map(agendaObj =>
		normalizeWorkerAgendaLine(agendaObj, weekStart, mutableObject)
	)
	return fmtData
}

export const normalizeFullMonthAgenda = data => {
	if (data.events.length === 0) {
		// This can happen when a request is made for a month where the worker does not have a contract
		return []
	}
	let monthStart = moment(data.start_time).startOf("month")
	let weeksInMonth = []
	let m = moment(monthStart).startOf("isoweek")
	while (
		m.isSame(monthStart, "month") ||
		moment(m)
			.add(7, "days")
			.isSame(monthStart, "month")
	) {
		weeksInMonth.push(moment(m))
		m.add(7, "days")
	}
	let fmtData = []
	let mutableObject = { emptyRangeSequentialId: 0 }
	weeksInMonth.forEach(weekStart => {
		let parsedAgendaData = data.events.map(entry => agendaEventDataParser(entry, weekStart))
		// There should only be one worker at a time, meaning parsedAgendaData should by an
		// array containing one object. But workers with multiple contracts happening within
		// the time-period will have multiple lines that should be put together
		let weekEnd = moment(weekStart).endOf("week")
		let singleAgendaObjectForWeek = parsedAgendaData[0]
		if (parsedAgendaData.length > 1) {
			let accInit = {
				timeRanges: [],
				cleaner: {
					// cheating the contrat id. Need API change to do this better.
					id: parsedAgendaData[0].cleaner.affiliateWorkerId,
					affiliateWorkerId: parsedAgendaData[0].cleaner.affiliateWorkerId,
					name: parsedAgendaData[0].cleaner.name,
					initials: parsedAgendaData[0].cleaner.initials,
					sodexoNumber: parsedAgendaData[0].cleaner.sodexoNumber,
					lastEuAbsenceDate: parsedAgendaData[0].cleaner.lastEuAbsenceDate,
					// Cheating the contract start / end. Multiple contracts may be gathered within
					// the same week.
					contractStart: moment(weekStart),
					contractEnd: moment(weekEnd),
				},
			}
			singleAgendaObjectForWeek = parsedAgendaData.reduce((acc, contractAgendaObject) => {
				let cs = contractAgendaObject.cleaner.contractStart
				let ce = contractAgendaObject.cleaner.contractEnd
				if (cs.isAfter(weekEnd) || (ce && ce.isBefore(weekStart))) {
					return acc
				}
				acc.timeRanges = acc.timeRanges.concat(
					contractAgendaObject.timeRanges.filter(
						tr =>
							tr.end.isSameOrAfter(cs, "day") &&
							(!ce || tr.start.isSameOrBefore(ce, "day"))
					)
				)
				acc.weekData = contractAgendaObject.weekData
				return acc
			}, accInit)
		}

		if (singleAgendaObjectForWeek.weekData) {
			// this means a corresponding contract-line was found
			// if there is no contract for this week weekData will never be added
			fmtData.push(
				normalizeWorkerAgendaLine(singleAgendaObjectForWeek, weekStart, mutableObject)
			)
		}
	})
	return fmtData
}

export const normalizeEventAgendaRow = (startTime, row) => {
	let weekStart = moment(startTime)
	let parsedAgendaRow = agendaEventDataParser(row, weekStart)
	let mutableObject = { emptyRangeSequentialId: 0 }
	let fmtData = normalizeWorkerAgendaLine(parsedAgendaRow, weekStart, mutableObject)
	return fmtData
}

export const normalizeAgendaData = data => {
	let parsedAgendaData = data.weekly_agenda.map(agendaDataParser)
	let weekStart = moment(data.start_time)
	let mutableObject = { emptyRangeSequentialId: 0 }
	let fmtData = parsedAgendaData.map(agendaObj =>
		normalizeWorkerAgendaLine(agendaObj, weekStart, mutableObject)
	)
	return fmtData
}

export const normalizeAgendaRow = (startTime, row) => {
	let parsedAgendaRow = agendaDataParser(row)
	let weekStart = moment(startTime)
	let mutableObject = { emptyRangeSequentialId: 0 }
	let fmtData = normalizeWorkerAgendaLine(parsedAgendaRow, weekStart, mutableObject)
	return fmtData
}
