import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import ModalLayout from "./ModalLayout"
import { PootsyButton, PootsySubHeading } from "./FunctionalDesign"
import { PootsySelectInput, PootsyCheckboxInput, PootsyRadioInputs } from "./FunctionalInputs"
import CheckMark from "../svg/checkMark"

import arrowRightIcon from "../static/images/arrow-right-icon.png"

const recurrencesTranslations = ["once", "weekly", "bimonthly", "monthly"]

class ImpactedBookingsModal extends Component {
	componentDidMount = () => {
		if (this.props.impactedBookingsModal.fetched) {
			this.checkValidity()
			this.collapseRowsThatShouldBe()
		}
	}
	componentDidUpdate = prevProps => {
		let { impactedBookingsModal } = this.props
		if (!prevProps.impactedBookingsModal.fetched && impactedBookingsModal.fetched) {
			this.checkValidity()
			this.collapseRowsThatShouldBe()
		}
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetImpactedBookings())
	}
	handleForceUnlinkChanges = async e => {
		await this.props.dispatch(actions.impactedBookingsChange({ forceUnlink: e.target.checked }))
		this.checkValidity()
	}
	checkValidity = () => {
		let {
			impactedBookings,
			impactedBookingsModal: {
				keepImpactedOAs,
				allowedOptions,
				linkedOccurrences,
				linkedWorkshopOrders,
				forceUnlink,
			},
		} = this.props
		let { ready: readyForSubmit } = actions.checkImpactedBookingsReadyForSubmit(
			impactedBookings,
			keepImpactedOAs,
			allowedOptions,
			linkedOccurrences,
			linkedWorkshopOrders,
			forceUnlink
		)
		this.props.dispatch(actions.impactedBookingsChange({ readyForSubmit }))
	}
	collapseRowsThatShouldBe = () => {
		let { dispatch, impactedBookings } = this.props
		// after fetching, rows with less than 3 occurrences impacted
		// will be defaulted to manualOccurrencesProcess, then they'll be collapsed here
		dispatch(
			actions.impactedBookingsChange({
				collapsedRows: Object.keys(impactedBookings).reduce((acc, key) => {
					let booking = impactedBookings[key]
					if (booking.manualOccurrencesProcess) {
						acc.push(Number(key))
					}
					return acc
				}, []),
			})
		)
	}
	handleImpactedBookingActionChange = e => {
		e.stopPropagation()
		let { dispatch } = this.props
		let { name, value } = e.target
		let bookingID = Number(name)
		dispatch(actions.impactedBookingsChange({ impactedBookingIDSelected: "" }))
		this.editImpactedBooking(bookingID, { action: value, replacementWorkerID: "" })
	}
	handleManualProcessChange = e => {
		e.stopPropagation()
		let { name, checked } = e.target
		let {
			impactedBookingsModal: { collapsedRows },
		} = this.props
		let bookingID = Number(name)
		this.editImpactedBooking(bookingID, {
			manualOccurrencesProcess: checked,
			replacementWorkerID: "",
			action: "",
		})
		this.props.dispatch(
			actions.impactedBookingsChange({
				impactedBookingIDSelected: "",
				collapsedRows: checked
					? [...collapsedRows, bookingID]
					: collapsedRows.filter(entry => entry !== bookingID),
			})
		)
	}
	handleImpactedOccurrenceActionChange = e => {
		e.stopPropagation()
		let { dispatch } = this.props
		let { name, value } = e.target
		let [bookingID, bookingOccurrenceID] = name.split("::")
		bookingID = Number(bookingID)
		dispatch(actions.impactedBookingsChange({ impactedBookingIDSelected: "" }))
		this.editImpactedOccurrence(bookingID, bookingOccurrenceID, {
			action: value,
			replacementWorkerID: "",
		})
	}
	editImpactedBooking = (id, changes) => {
		let { dispatch } = this.props
		dispatch(actions.processImpactedBookingChange(id, changes))
	}
	editImpactedOccurrence = (bookingID, occurrenceID, changes) => {
		let { dispatch } = this.props
		dispatch(actions.processImpactedOccurrenceChange(bookingID, occurrenceID, changes))
	}
	collapseRow = e => {
		let {
			dispatch,
			impactedBookingsModal: { collapsedRows },
		} = this.props
		let { bookingid } = e.target.dataset
		let bookingID = Number(bookingid)
		dispatch(
			actions.impactedBookingsChange({
				collapsedRows: collapsedRows.includes(bookingID)
					? collapsedRows.filter(entry => entry !== bookingID)
					: [...collapsedRows, bookingID],
				impactedBookingIDSelected: -1,
			})
		)
	}
	closeModal = () => {
		let { dispatch, closeModal } = this.props
		dispatch(actions.resetImpactedBookings())
		if (closeModal) {
			closeModal()
		}
	}
	handleKIOAChange = async e => {
		let keepImpactedOAs = e.target.value
		await this.props.dispatch(actions.impactedBookingsChange({ keepImpactedOAs }))
		this.checkValidity()
	}
	toggleCreateReplacementForAll = e => {
		let { dispatch } = this.props
		dispatch(actions.changeAllImpactedOccurrences({ action: "create_pending_replacement" }))
		dispatch(
			actions.impactedBookingsChange({
				createReplacementForAll: e.target.checked,
				createReplacementForNone: false,
			})
		)
	}
	toggleCreateReplacementForNone = e => {
		let { dispatch } = this.props
		dispatch(
			actions.changeAllImpactedOccurrences({ action: "do_not_create_pending_replacement" })
		)
		dispatch(
			actions.impactedBookingsChange({
				createReplacementForAll: false,
				createReplacementForNone: e.target.checked,
			})
		)
	}
	submitImpactedBookingsActions = () => {
		let { onSubmit, dispatch } = this.props
		dispatch(actions.submitImpactedBookingsActions(onSubmit))
	}
	renderImpactedOtherActivities = () => {
		let { t } = this.context
		let {
			impactedBookingsModal: { keepImpactedOAs },
		} = this.props
		return (
			<Fragment>
				<PootsySubHeading text={t("impacted_other_activities_section")} />
				<PootsyRadioInputs
					groupName="keepImpactedOAs"
					groupLabel={t("impacted_other_activities_action")}
					selected={keepImpactedOAs}
					onChange={this.handleKIOAChange}
					options={[
						{ id: "yes", value: "yes", label: t("keep_created_oas") },
						{ id: "no", value: "no", label: t("remove_created_oas") },
					]}
				/>
			</Fragment>
		)
	}
	renderImpactedBookings = () => {
		let { t } = this.context
		let {
			impactedBookings,
			impactedBookingsModal: {
				impactedBookingIDSelected,
				linkedOccurrences,
				collapsedRows,
				showRequired,
				allowedOptions,
				createReplacementForAll,
				createReplacementForNone,
			},
		} = this.props

		let linkedOccurrencesIds = linkedOccurrences.map(entry => entry.id)

		return (
			<div className="impacted-bookings-section">
				<div className="description">{t("impacted_bookings_description")}</div>
				<div className="global-actions">
					<div className="description">
						{t("global_action_for_all_impacted_occurrences")}
					</div>
					<PootsyCheckboxInput
						name="createReplacementForAll"
						label={t("create_replacement_for_all_impacted_occurrences")}
						checked={createReplacementForAll}
						onChange={this.toggleCreateReplacementForAll}
					/>
					<PootsyCheckboxInput
						name="createReplacementForNone"
						label={t("do_not_create_replacement_for_all_impacted_occurrences")}
						checked={createReplacementForNone}
						onChange={this.toggleCreateReplacementForNone}
					/>
				</div>
				<PootsySubHeading text={t("impacted_bookings")} />
				<div className="impacted-bookings">
					{Object.keys(impactedBookings).map(bookingID => {
						let impactedBooking = impactedBookings[bookingID]
						let collapsed =
							impactedBooking.manualOccurrencesProcess &&
							collapsedRows.includes(Number(bookingID))
						let className = "impacted-booking"
						className +=
							impactedBookingIDSelected === bookingID
								? " active-replacement-selection"
								: ""
						className +=
							impactedBooking.replacementWorkerID ||
							impactedBooking.action === "cancel"
								? " ready-for-submit"
								: ""
						return (
							<div key={bookingID} className={className}>
								{impactedBooking.hoursOverlay && (
									<div className="warning">
										{t("hours_overlay_warning_prompt")}
									</div>
								)}
								<div className="top-part">
									<div className="column collapser-arrow">
										{impactedBooking.manualOccurrencesProcess && (
											<div
												className={
													"arrow-container" +
													(collapsed ? " collapsed" : "")
												}
												data-bookingid={bookingID}
												onClick={this.collapseRow}
											>
												<img
													className="arrow-img"
													src={arrowRightIcon}
													alt="arrow"
												/>
											</div>
										)}
									</div>
									<div className="column customer">
										<div className="value">{impactedBooking.customer}</div>
									</div>
									<div className="column recurrences">
										<div className="value">
											{t(
												recurrencesTranslations[
													impactedBooking.recurrenceDelay
												]
											)}
										</div>
									</div>
									<div className="column action-selection">
										<PootsySelectInput
											defaultText={t("action_on_all_occurrence")}
											name={bookingID}
											onChange={this.handleImpactedBookingActionChange}
											selected={impactedBooking.action}
											showRequired={showRequired.includes(bookingID)}
											disabled={
												impactedBooking.manualOccurrencesProcess ||
												impactedBookings[bookingID].impactedOccurrences
													.length < 2 ||
												createReplacementForAll ||
												createReplacementForNone
											}
											options={[
												{
													label: t("create_pending_replacement"),
													value: "create_pending_replacement",
												},
												{
													label: t("do_not_create_pending_replacement"),
													value: "do_not_create_pending_replacement",
												},
											]}
										/>
									</div>
									<div className="column manage-occurrences-toggle">
										{allowedOptions.includes("impacted_occurrences") && (
											<PootsyCheckboxInput
												name={bookingID}
												label={t(
													"manage_impacted_occurrences_individually"
												)}
												checked={impactedBooking.manualOccurrencesProcess}
												onChange={this.handleManualProcessChange}
												disabled={
													createReplacementForAll ||
													createReplacementForNone
												}
											/>
										)}
									</div>
								</div>
								<div
									className={`impacted-occurrences ${
										collapsed ? "collapsed" : ""
									}`}
								>
									{impactedBookings[bookingID].impactedOccurrences.map(entry => {
										let className = "impacted-occurrence"
										className +=
											impactedBookingIDSelected === entry.id
												? " active-replacement-selection"
												: ""
										className +=
											entry.replacementWorkerID || entry.action === "cancel"
												? " ready-for-submit"
												: ""
										return (
											<div key={entry.id} className={className}>
												<div className="column checkmark">
													{linkedOccurrencesIds.includes(entry.id) && (
														<CheckMark blueFill={true} />
													)}
												</div>
												<div className="column next-occurrence">
													<div className="value">
														{moment(entry.scheduled_date).format(
															"dddd DD/MM/YYYY"
														)}{" "}
														{moment(entry.start_time).format("HH:mm")}
													</div>
												</div>
												<div className="column action-selection">
													<PootsySelectInput
														defaultText={t("action_on_one_occurrence")}
														name={bookingID + "::" + entry.id}
														onChange={
															this
																.handleImpactedOccurrenceActionChange
														}
														selected={entry.action}
														showRequired={showRequired.includes(
															entry.id
														)}
														disabled={
															createReplacementForAll ||
															createReplacementForNone
														}
														options={[
															{
																label: t(
																	"create_pending_replacement"
																),
																value: "create_pending_replacement",
															},
															{
																label: t(
																	"do_not_create_pending_replacement"
																),
																value:
																	"do_not_create_pending_replacement",
															},
														]}
													/>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
	render() {
		let { t } = this.context
		let {
			impactedBookings,
			triggerActionExpected,
			impactedBookingsModal: {
				readyForSubmit,
				allowedOptions,
				linkedOccurrences,
				forceUnlink,
				triggerAction,
				linkedPvdg,
				linkedWorkshopOrders,
			},
		} = this.props
		if (Array.isArray(triggerActionExpected)) {
			if (!triggerActionExpected.includes(triggerAction)) {
				return null
			}
		} else if (triggerActionExpected !== triggerAction) {
			return null
		}

		return (
			<ModalLayout
				closeModal={this.closeModal}
				formDivClass="impacted-bookings-modal"
				title={
					Object.keys(impactedBookings).length > 0
						? t("impacted_bookings_modal_title")
						: t("linked_pluxee_modal_title")
				}
				buttons={[
					<PootsyButton
						key="a"
						text={t("cancel")}
						onClick={this.closeModal}
						theme="cancel"
					/>,
					<PootsyButton
						key="b"
						text={t("submit")}
						onClick={this.submitImpactedBookingsActions}
						theme={readyForSubmit ? "green" : "cancel"}
					/>,
				]}
			>
				{linkedPvdg ? (
					<div className="linked-in-pvdg-warning">
						{t("ibo_have_linked_items_error_pvdg")}
						{linkedPvdg.linked_occurrences && (
							<div className="bookings-in-pvdg">
								{linkedPvdg.linked_occurrences.map(entry => (
									<div key={entry.id}>
										- {moment(entry.delivery_date).format("DD/MM/YY")} -{" "}
										{entry.formated_start_time}
									</div>
								))}
							</div>
						)}
						{linkedPvdg.linked_laundry_orders && (
							<div className="bookings-in-pvdg">
								{linkedPvdg.linked_laundry_orders.map(entry => (
									<div key={entry.id}>
										- {moment(entry.execution_date).format("DD/MM/YY")} -{" "}
										{entry.worker_name}
									</div>
								))}
							</div>
						)}
					</div>
				) : (
					<Fragment>
						{allowedOptions.includes("impacted_other_activities") && (
							<div className="impacted-other-activities-section">
								{this.renderImpactedOtherActivities()}
							</div>
						)}
						{Object.keys(impactedBookings).length > 0 && this.renderImpactedBookings()}
						{(linkedOccurrences.length > 0 || linkedWorkshopOrders.length > 0) && (
							<div className="sodexo-unlinking">
								<div className="details">
									{Object.keys(impactedBookings).length > 0
										? t("ibo_have_linked_items_error_1")
										: t("linked_pluxee_without_bookings")}
								</div>
								<PootsyCheckboxInput
									name="forceUnlink"
									label={t("force_sodexo_unlink")}
									checked={forceUnlink}
									onChange={this.handleForceUnlinkChanges}
								/>
							</div>
						)}
					</Fragment>
				)}
			</ModalLayout>
		)
	}
}

ImpactedBookingsModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
	impactedBookings: state.redData.impactedBookings,
	impactedBookingsModal: state.redComponents.impactedBookingsModal,
})
export default connect(mapStateToProps)(ImpactedBookingsModal)
